import React from "react"
import BtfData from "./data/bf.json"
import Styles from "./css/bf.module.scss"
import {isChrome,isFirefox,isIeEdge,isSafari, getBrowser} from "@tightrope/lpscripts/browserdetect"

class belowFold extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      isSafari: isSafari(),
      display: 'none',
      pageData: props.data,
      data: BtfData
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
   else if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
   else if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
   else if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
   else if (getBrowser() === "safari" && browsers.includes("safari")) {
      display = true;
    }
   else if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();
    this.state.isSafari = isSafari();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
      if (isSafari()) {
        const browserData = Object.assign(this.state.data, this.state.data.safari);
        this.setState({ data: browserData });
      }
      if (isIeEdge()) {
        const browserData = Object.assign(this.state.data, this.state.data.edge);
        this.setState({ data: browserData });
      }

      if (typeof window != "undefined") {
      window.mapParams();
    }
    });
  }

  render() {
    return (
      <section style={{ display: this.state.display }}>
      <div className = {Styles.belowFold}>
      <div className = {Styles.middle2}>
      <img alt='Paperboy' src={this.state.data.clearSS}/>
      <div>
      <h3>{this.state.data.mid2Headline}</h3>
      <h4>{this.state.data.mid2Sub1}</h4>
      <p>{this.state.data.mid2Line1}</p>
      <h4>{this.state.data.mid2Sub2}</h4>
      <p>{this.state.data.mid2Line2}</p>
      <h4>{this.state.data.mid2Sub3}</h4>
      <p>{this.state.data.mid2Line3}</p>
      </div>

      </div>

      <div className = {Styles.bottom}>
      		<h3 className = {Styles.headlineMid}> {this.state.data.freedom}</h3>
          <div>
      		<div className = {Styles.bottomTile}>
          <h4>{this.state.data.freedomHead1}</h4>
          <p>{this.state.data.freedomPara1}</p>
          </div>
          <div className = {Styles.bottomTile}>
          <h4>{this.state.data.freedomHead2}</h4>
          <p>{this.state.data.freedomPara2}</p>
          </div>
          <div className = {Styles.bottomTile}>
          <h4>{this.state.data.freedomHead3}</h4>
          <p>{this.state.data.freedomPara3}</p>
          </div>
          </div>
      </div>

          <section className="download-section">
            <div className="container">
              <div className="download-content">
                <a
                  role="button"
                  className="cta-button primary"
                  onClick={() => { window.triggerInstall() }}
                  data-cy="cta"
                >
                  {this.state.data.cta}
                </a>
                <div className="download-disclosure">
                {this.props.children}
                </div>
              </div>
            </div>
          </section>
          </div>
          {this.props.footer}
      </section>

    )
  }
}

export default belowFold
